import React from "react"
import styled from "styled-components"

import leader1 from "../assets/images/leader1.jpg"
import lukasz from "../assets/images/lukasz_gawronski.webp"
import imageBanner from "../assets/images/banner_bg_2.png"
import kgawronska from "../assets/images/kgawronska-medinvest.jpg"
import kwisiorowski from "../assets/images/kacper-wisiorowski-medinvest.jpg"

import { StyledOnasHeader } from "../components/OnasHeader/OnasHeader.styled"
import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/SEO"
import H1 from "../components/H1/H1"
import H2 from "../components/H2/H2"
import ContentWrapper from "../components/ContentWrapper/ContentWrapper"
import Banner from "../components/Banner/Banner"
// import CardPerson from "../components/CardPerson/CardPerson"
import OnasHeader from "../components/OnasHeader/OnasHeader"

const PersonalImage = styled.div`
  display: flex;
  margin-bottom: 100px;
  flex-direction: column;
  height: auto;
  overflow: visible;
  ${({ theme }) => theme.media.tablet} {
    flex-direction: ${({ even }) => (even ? "row-reverse" : "row")};
    margin: ${({ even }) => (even ? "0 0 150px 50px" : "0 50px 150px 0")};
  }
  img {
    object-fit: cover;
    height: auto;
    width: 300px;
    margin: 0 auto 50px auto;
    ${({ theme }) => theme.media.tablet} {
      height: 500px;
      margin: ${({ even }) => (even ? "0 0 0 50px" : "0 50px 0 0")};
    }
  }
  div {
    h2 {
      padding-left: 65px;
      ::before {
        content: "";
        height: 4px;
        width: 48px;
        background: ${({ theme }) => theme.colors.primary};
        display: inline-block;
        position: absolute;
        left: 0;
        top: calc(50% - 2px);
      }
    }
  }
`

const PartnersPage = () => {
  return (
    <Layout>
      <SEO title="O nas" />
      <div style={{ height: "60px" }} />
      <Banner title="O NAS" image={imageBanner} />
      <ContentWrapper>
        <br />
        <br />
      </ContentWrapper>
      <ContentWrapper>
        <OnasHeader />
        <PersonalImage>
          <img src={leader1} alt="Łukasz Gawroński" />
          <div>
            <H1>Łukasz Gawroński</H1>
            <H2 gold>Prezes Zarządu</H2>
            <p>
              Dzień dobry! Nazywam się Łukasz Gawroński. Na co dzień jestem przedsiębiorcą, managerem a przede wszystkim profesjonalnym pośrednikiem przy sprzedaży firm i w obrocie nieruchomościami sektora medycznego. Doświadczenie zdobywałem latami – studiując finanse i rachunkowość, prawo oraz na stanowiskach managera agencji nieruchomości, biura deweloperskiego oraz zarządzając przychodnią lekarską POZ. Rzadziej, ale z niemniejszym zapałem handlowałem towarami oraz sprzętem medycznym. Z branżą jestem związany od najmłodszych lat. Pochodzę bowiem z rodziny z lekarską tradycją od dziada-pradziada. W naturalny sposób sprawiło to, że już jako dziecko zainteresowałem się medycyną. Dorastałem, uczony jak zarządzać w służbie zdrowia przez osoby piastujące najwyższe kierownicze stanowiska we flagowych Warszawskich szpitalach. Wszystko, aby finalnie osiągnąć najwyższy poziom obsługi klienta branżowego. Wszystko, aby stworzyć MedInvest.
            </p>
          </div>
        </PersonalImage>
        <PersonalImage>
          <img src={kgawronska} alt="Katarzyna Gawrońska" />
          <div>
            <H1>Lek. med. Katarzyna Gawrońska</H1>
            <H2 gold>Dyrektor ds. Marketingu i Finansów</H2>
            <p>
              Nazywam się Katarzyna Gawrońska i dla firmy MedInvest pracuję jako Dyrektor Marketingowy oraz Finansowy. Przez lata dorastałam rozwijając i pielęgnując w sobie zaszczepione przez rodzinę pasję oraz zainteresowanie medycyną. Jestem absolwentką wydziału lekarskiego Warszawskiego Uniwersytetu Medycznego i pracuję jako lekarz. Posiadam nie tylko dużo doświadczeń związanych z pracą w przychodniach POZ czy szpitalach, ale przede wszystkim pośrednictwie sprzedaży tego typu przedsiębiorstw. Jeśli szukasz profesjonalistki, która zna branże od podszewki i z powodzeniem łączy pracę lekarza z pośrednictwem sprzedaży firm sektora medycznego skontaktuj się ze mną!
            </p>
          </div>
        </PersonalImage>
        <PersonalImage>
          <img src={kwisiorowski} alt="Kacper Wisiorowski" />
          <div>
            <H1>Kacper Wisiorowski</H1>
            <H2 gold>Dyrektor ds. fuzji i przejęć</H2>
            <p>
              Nazywam się Kacper Wisiorowski i od dwóch lat zajmuję się wyłącznie pośrednictwem w sprzedaży firm z sektora medycznego. Moje doświadczenie w pośrednictwie sięga jeszcze czasów, gdy pracowałem w biurzey nieruchomości, zdobywając szeroką wiedzę o rynku i strategiach inwestycyjnych. Jednak od kiedy skoncentrowałem się na sprzedaży podmiotów medycznych, w pełni zaangażowałem się w zrozumienie specyfiki tej branży, co przełożyło się na rzeszę zadowolonych klientów W 2024 roku miałem przyjemność uczestniczyć w sprzedaży największej sieci przychodni POZ w woj. Świętokrzyskim, co było dla mnie dużym krokiem w karierze i potwierdzeniem skuteczności mojej strategii działania. Pochodzę z rodziny przedsiębiorców, więc wartości związane z samodzielnym budowaniem sukcesu i zarządzaniem kapitałem są mi bliskie od najmłodszych lat. Moja dotychczasowa droga zawodowa, od nieruchomości inwestycyjnych po dzisiejszą specjalizację w sprzedaży firm medycznych, daje mi unikalną perspektywę i doświadczenie, które pozwalają mi precyzyjnie trafiać w potrzeby klientów. Dzięki temu jestem w stanie zapewnić profesjonalną obsługę na najwyższym poziomie, dostosowaną do indywidualnych oczekiwań moich partnerów biznesowych. Jeśli szukasz eksperta od sprzedaży firm medycznych, który dysponuje nie tylko doświadczeniem, ale także pasją i determinacją w osiąganiu sukcesu, zapraszam do kontaktu. Razem znajdziemy najlepsze rozwiązanie dla Ciebie i Twojej firmy.
            </p>
          </div>
        </PersonalImage>
      </ContentWrapper>
    </Layout>
  )
}

export default PartnersPage
