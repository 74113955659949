import React from "react"

import { StyledOnasHeader } from "./OnasHeader.styled"

import H1 from "../H1/H1"
import H2 from "../H2/H2"

const OnasHeader = () => {
  return (
    <StyledOnasHeader>
      <div>
        <div>
          <H1>O NAS</H1>
          <H2 gold>Warszawa</H2>
          <p>
            Firma powstała z pasji, poczucia misji wypełnienia pewnej niszy.
            Brakowało bowiem na rynku podmiotu pomagającego realizować swoje
            potrzeby biznesowe poprzez wsparcie przy zarządzaniu, budowie i
            sprzedaży spółek medycznych oraz nieruchomości dedykowanych usługom
            medycznym. Stanowiąc połączenie profesjonalnego zespołu
            sprzedażowego, prawników, lekarzy oraz osób na co dzień
            zarządzających placówkami medycznymi, MedInvest oferuje kompleksowe
            podejście do obrotu nieruchomościami oraz firmami sektora
            medycznego. MedInvest to przede wszystkim doskonale dobrany,
            wyselekcjonkwany zespół specjalistów, a nasze usługi obejmują
            całościową opiekę nad przeprowadzaną transakcją. W skład
            podejmowanych przez nasz zespół działań wchodzi nie tylko
            poszukiwanie klientów, ale także kompleksowa obsługa transakcyjna, a
            także doradztwo potransakcyjne. Co więcej, pozyskane w trakcie
            naszej drogi zawodowej relacje z inwestorami z branży medycznej oraz
            deweloperami pozwalają w szybki sposób sfinalizować większość
            transakcji. Współpracujemy także z właścicielami największych
            koncernów medycznych, czy grup kapitałowych.
          </p>
        </div>
      </div>
    </StyledOnasHeader>
  )
}

export default OnasHeader
