import styled from "styled-components"

export const StyledOnasHeader = styled.div`
  div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    top: 0;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    overflow-x: hidden;
    margin: 0 auto 50px auto;

    ${({ theme }) => theme.media.tablet} {
      margin-bottom: 100px;
    }
    ${({ theme }) => theme.media.desktop} {
      margin-bottom: 150px;
      flex-direction: row;
    }
    div {
      margin-bottom: 30px;
      flex-direction: column;
      align-items: flex-start;

      ${({ theme }) => theme.media.desktop} {
        margin-bottom: 0;
      }

      h1 {
        color: ${({ theme }) => theme.colors.secondary};
      }
      h2 {
        padding-left: 65px;
        ::before {
          content: "";
          height: 4px;
          width: 48px;
          background: ${({ theme }) => theme.colors.primary};
          display: inline-block;
          position: absolute;
          left: 0;
          top: calc(50% - 2px);
        }
      }

      p {
        ${({ theme }) => theme.media.tablet} {
        }

        ${({ theme }) => theme.media.desktop} {
        }
      }
    }
  }
`
